import { useCallback } from 'react';
import { Observable, map } from 'rxjs';
import {
	PromptEditType,
	SessionPromptMetadata
} from 'charlie-workflows/contracts/prompts';
import { useHttp } from '../http/http.service';

export type UseConversationsApiHook = () => {
	branchConversation: (sharedId: string) => Observable<string>;
	getUserPrompts: () => Observable<Record<PromptEditType, string | undefined>>;
	resetToDefaultPrompts: () => Observable<
		Record<PromptEditType, string | undefined>
	>;
	saveConversationPrompt: (
		promptMetadata: SessionPromptMetadata
	) => Observable<Record<PromptEditType, string | undefined>>;
};

export const useConversationsApi: UseConversationsApiHook = () => {
	const { makeRequest } = useHttp();

	const branchConversation = useCallback(
		(sharedId: string): Observable<string> => {
			return makeRequest<{ id: string }>('/api/branch', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ sharedId })
			}).pipe(map((response) => response.id));
		},
		[]
	);

	const getUserPrompts = useCallback((): Observable<
		Record<PromptEditType, string | undefined>
	> => {
		return makeRequest<{ prompts: Record<PromptEditType, string | undefined> }>(
			'/api/prompt-edit',
			{
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			}
		).pipe(map((response) => response.prompts));
	}, []);

	const resetToDefaultPrompts = useCallback((): Observable<
		Record<PromptEditType, string | undefined>
	> => {
		return makeRequest<{ prompts: Record<PromptEditType, string | undefined> }>(
			'/api/prompt-edit/reset-to-default',
			{
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			}
		).pipe(map((response) => response.prompts));
	}, []);

	const saveConversationPrompt = useCallback(
		(
			promptMetadata: SessionPromptMetadata
		): Observable<Record<PromptEditType, string | undefined>> => {
			return makeRequest<Record<PromptEditType, string | undefined>>(
				'/api/prompt-edit',
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(promptMetadata)
				}
			);
		},
		[]
	);

	return {
		branchConversation,
		getUserPrompts,
		resetToDefaultPrompts,
		saveConversationPrompt
	};
};
